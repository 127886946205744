var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { class: _vm.classes }, [
    _c(
      "a",
      {
        directives: [
          {
            name: "ripple",
            rawName: "v-ripple",
            value: _vm.isPublishable
              ? _vm.$utilities.getRippleOptions("light")
              : false,
            expression:
              "isPublishable ? $utilities.getRippleOptions('light') : false",
          },
        ],
        attrs: { href: _vm.getContentDetailPath(_vm.content.recipe_id) },
      },
      [
        _c(
          "div",
          {
            ref: "cardContent",
            staticClass: "dp-top-recipe-carousel-card__content",
          },
          [
            _c("h2", { staticClass: "dp-top-recipe-carousel-card__title" }, [
              _vm._v(" " + _vm._s(_vm.content.name) + " "),
            ]),
            _vm.content.capsule && _vm.isPublishPeriod(_vm.content.capsule)
              ? _c(
                  "div",
                  { staticClass: "dp-top-recipe-carousel-card__capsule" },
                  [
                    _c("Icon", {
                      attrs: {
                        name: "capsuleB",
                        color: "dpGrey66",
                        width: 11,
                        height: 11,
                      },
                    }),
                    _c(
                      "span",
                      {
                        staticClass:
                          "dp-top-recipe-carousel-card__capsule-name",
                      },
                      [_vm._v(_vm._s(_vm.content.capsule.name))]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "dp-top-recipe-carousel-card__thumbnail" },
              [
                _c("img", {
                  attrs: { src: _vm.content.image_url, alt: _vm.content.name },
                }),
              ]
            ),
            !_vm.isPublishable
              ? _c(
                  "p",
                  { staticClass: "dp-top-recipe-carousel-card__closed" },
                  [_vm._v(" " + _vm._s(_vm.WORDS.DRIP_POD_TOP.CLOSED) + " ")]
                )
              : _vm._e(),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }