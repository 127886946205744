<template>
  <div :class="classes">
    <a
      :href="getContentDetailPath(content.recipe_id)"
      v-ripple="isPublishable ? $utilities.getRippleOptions('light') : false">
      <div ref="cardContent" class="dp-top-recipe-carousel-card__content">
        <h2 class="dp-top-recipe-carousel-card__title">
          {{ content.name }}
        </h2>

        <div
          v-if="content.capsule && isPublishPeriod(content.capsule)"
          class="dp-top-recipe-carousel-card__capsule">
          <Icon name="capsuleB" color="dpGrey66" :width="11" :height="11" />
          <span class="dp-top-recipe-carousel-card__capsule-name">{{
            content.capsule.name
          }}</span>
        </div>

        <div class="dp-top-recipe-carousel-card__thumbnail">
          <img :src="content.image_url" :alt="content.name" />
        </div>

        <p v-if="!isPublishable" class="dp-top-recipe-carousel-card__closed">
          {{ WORDS.DRIP_POD_TOP.CLOSED }}
        </p>
      </div>
    </a>
  </div>
</template>

<script>
import { WORDS } from '@/constants/words';
import { defineComponent, ref, computed } from '@vue/composition-api';
import { usePublishable } from '@/composables/usePublishable';

export default defineComponent({
  props: {
    content: {
      type: Object,
      default: undefined
    }
  },

  setup: (props, context) => {
    const { isPublishPeriod } = usePublishable();
    const cardContent = ref(undefined);
    const isPublishable = computed(() => isPublishPeriod(props.content));
    const getContentDetailPath = (id) =>
      isPublishable.value && id
        ? context.root.$customUrlScheme.dp(`/pro-recipe/detail/${id}`)
        : false;

    const classes = computed(() => [
      'dp-top-recipe-carousel-card',
      !isPublishable.value ? 'dp-top-recipe-carousel-card--disabled' : false
    ]);

    return {
      WORDS,
      classes,
      cardContent,
      isPublishable,
      isPublishPeriod,
      getContentDetailPath
    };
  }
});
</script>

<style lang="scss" scoped>
@use '@/assets/scss/variables';
@use '@/assets/scss/mixin';

.dp-top-recipe-carousel-card {
  display: grid;
  grid-template-rows: 1fr;

  &--disabled {
    > * {
      opacity: 0.7;
    }

    .dp-top-recipe-carousel-card__content {
      background-color: variables.$dpGreyF1;
      border: rgba(variables.$dpGreyAa, 0.2) 1px solid;
    }
  }

  &__closed {
    position: absolute;
    bottom: 1px;
    left: 0;
    margin: 0;
    font-size: 10px;
    transform: scale(0.8);
  }

  a {
    position: relative;
    display: grid;
    grid-template-rows: 1fr;
    text-decoration: none;
    color: variables.$dpBlack01;
  }

  &__content {
    position: relative;
    display: grid;
    grid-template-rows: 1fr;
    margin: 0;
    padding: 16px;
    background-color: variables.$dpWhite01;
    border-radius: 4px;
    box-shadow: 0 4px 16px rgba(#000, 0.16);
    overflow: hidden;

    > *:last-child {
      margin-bottom: 0;
    }
  }

  &__capsule {
    display: grid;
    place-items: center start;
    grid-template-columns: 12px 1fr;
    gap: 2px;
    color: variables.$dpGrey66;
    font-size: 10px;

    &-name {
      @include mixin.clamp(1);
    }
  }

  &__title {
    @include mixin.clamp(2);

    margin-bottom: 8px;
    font-size: 16px;
    line-height: 1.3;
    font-weight: bold;
    height: calc(16px * 2 * 1.3);
  }

  &__thumbnail {
    @include mixin.imageAspectRatio(343, 343);

    place-self: center end;
    margin-top: 10px;
    overflow: hidden;
  }
}
</style>
